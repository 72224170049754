<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 100vh; background-color: black"
  >
    <div class="text-center">
      <h1 style="color:white;font-size:112px;font-weight:900">404</h1>
      <h4 style="font-weight:800">Not authorized !</h4>
      <router-link to="/" class="back-home-btn">
        Home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.back-home-btn{
  margin-top:18px;
  display: inline-block;
  padding: 8px 18px;
  font-weight: 900;
  border-radius: 4px;
  background-color: #fff;
}

</style>
